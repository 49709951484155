import { Deal_featuredDeal$key } from "__generated__/Deal_featuredDeal.graphql";
import _ from "lodash";
import { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveValue } from "styled-system";
import { Flex, Text } from "theme-ui";

import Ic20Email from "../../../../../../imgs/icons/ic20-email.svg";
import AutoLayout, {
  FillContainer,
  Fixed
} from "../../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../../components/01_Core/Buttons/LoadingButton";
import theme from "../../../../../theme/theme";
import MarketplaceAccessGate from "../../../../../utils/MarketplaceAccessGate";
import { formatDollars } from "../../../../../utils/money";
import { useActiveResponsiveValue } from "../../../../../utils/responsiveUtils";
import { useAuth } from "../../../hooks/useAuth";
import { useMessageActor } from "../../../hooks/useMessage";
import { IFundingOfferFormValues } from "../../07_Deal_Flow/OfferFormFunding";
import ReleaseImagesGrid from "./ReleaseImagesGrid";

type DealSize = "small" | "large";

interface IDealProps {
  size: ResponsiveValue<DealSize>;
  featuredDeal: Deal_featuredDeal$key;
}

/**
 * figma: https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=2364%3A17779
 */
function Deal(props: IDealProps): ReactElement {
  const featuredDeal = useFragment(
    graphql`
      fragment Deal_featuredDeal on FeaturedDealNode {
        totalAmountRaising
        inPocketAmountRaising
        marketingAmountRaising
        creativeAmountRaising
        partnerSplitPreRecoupment
        partnerSplitPostRecoupment
        termLengthMonths
        artist {
          ...useMessage_actor
          ...ReleaseImagesGrid_artist
        }
        soundRecordings {
          edges {
            node {
              id
            }
          }
        }
        releases {
          edges {
            node {
              id
              __typename
              isVisible
              ...ReleaseImagesGrid_releases
              ...UpdateOrCreateReleaseModal_release
            }
          }
        }
        unreleasedReleases {
          edges {
            node {
              __typename
              name
              id
              ...ReleaseImagesGrid_unreleasedReleases
              ...UpdateOrCreateReleaseModal_release
            }
          }
        }
      }
    `,
    props.featuredDeal
  );

  const activeSize = useActiveResponsiveValue(props.size);

  const authUser = useAuth().authUser;
  const userIsPartner = authUser?.userType === "partner";

  const offerFormInitialValues: Partial<IFundingOfferFormValues> = {
    service: "funding",
    advanceAmountCents:
      featuredDeal.inPocketAmountRaising &&
      featuredDeal.inPocketAmountRaising * 100,
    marketingAmountCents:
      featuredDeal.marketingAmountRaising &&
      featuredDeal.marketingAmountRaising * 100,
    creativeAmountCents:
      featuredDeal.creativeAmountRaising &&
      featuredDeal.creativeAmountRaising * 100,
    termLength: featuredDeal.termLengthMonths,
    soundRecordingIds: _.mapValues(
      _.keyBy(featuredDeal.soundRecordings.edges, ({ node: { id } }) => id),
      () => true
    ),
    allReleases: [
      ...featuredDeal.releases.edges.map(({ node }) => {
        return { typename: node.__typename, id: node.id };
      }),
      ...featuredDeal.unreleasedReleases.edges.map(({ node }) => {
        return { typename: node.__typename, id: node.id };
      })
    ],
    ...(featuredDeal.partnerSplitPreRecoupment !== null && {
      splitBeforeRecoupment: {
        partnerPercent: featuredDeal.partnerSplitPreRecoupment
      }
    }),
    ...(featuredDeal.partnerSplitPostRecoupment !== null && {
      splitAfterRecoupment: {
        partnerPercent: featuredDeal.partnerSplitPostRecoupment
      }
    })
  };

  const [messageArtist, isMessageArtistInFlight] = useMessageActor(
    featuredDeal.artist
    // {
    //   isMakingOffer: true,
    //   initialValues: offerFormInitialValues
    // }
  );

  const advanceBreakdown: [string, number][] = [
    ["In-pocket", featuredDeal.inPocketAmountRaising],
    ["Marketing", featuredDeal.marketingAmountRaising],
    ["Creative", featuredDeal.creativeAmountRaising]
  ];

  return (
    <AutoLayout
      spacing={24}
      resizingX={Fixed("328px")}
      dependentProps={{ direction: "vertical" }}
      sx={
        activeSize === "large" && {
          backgroundColor: "white",
          padding: "16px",
          borderRadius: "6px",
          boxShadow: "0px 3.10843px 9.32529px rgba(0, 0, 0, 0.15)"
        }
      }
    >
      <ReleaseImagesGrid
        artist={featuredDeal.artist}
        releases={featuredDeal.releases.edges.map(e => e.node)}
        unreleasedReleases={featuredDeal.unreleasedReleases.edges.map(
          e => e.node
        )}
      />
      {/* "Advance" */}
      <AutoLayout
        spacing={24}
        resizingX={FillContainer()}
        dependentProps={{ direction: "vertical" }}
      >
        {/* "Details" */}
        <Flex
          sx={{
            flexDirection: "column",
            width: "100%",
            alignItems: activeSize === "small" ? "center" : "flex-start"
          }}
        >
          <Text variant={"h300"} color={"black100"}>
            Raising
          </Text>
          <Text variant={"h500"} color={"black100"}>
            {featuredDeal.totalAmountRaising != null
              ? `$${formatDollars(featuredDeal.totalAmountRaising)}`
              : "Negotiable"}
          </Text>
        </Flex>

        {/* "Breakdown" */}
        <AutoLayout
          spacing={8}
          dependentProps={{ direction: "horizontal" }}
          sx={{ width: "100%" }}
        >
          {advanceBreakdown.map(([label, value]) => {
            return (
              <Flex key={label} sx={{ flexDirection: "column", flexGrow: 1 }}>
                <Text variant={"bodyMedium"} color={"black100"}>
                  {label}
                </Text>
                <Text variant={"h300"} color={"black100"}>
                  {value != null ? `$${formatDollars(value)}` : "—"}
                </Text>
              </Flex>
            );
          })}
        </AutoLayout>
        <MarketplaceAccessGate>
          <AutoLayout
            spacing={8}
            dependentProps={{ direction: "vertical" }}
            resizingX={FillContainer()}
          >
            <LoadingButton
              onClick={messageArtist}
              variant={"primary"}
              size={activeSize === "large" ? "large" : "medium"}
              disabled={!userIsPartner || undefined}
              loading={isMessageArtistInFlight}
              sx={{ width: "100%" }}
              iconProps={{
                placement: "left",
                icon: <Ic20Email color={theme.colors.white100 as string} />
              }}
            >
              Send a Message
            </LoadingButton>
            <Text variant={"bodySmall"} color={"deepGray100"}>
              {"Typical response times on indify are < 24 hours"}
            </Text>
          </AutoLayout>
        </MarketplaceAccessGate>
      </AutoLayout>
    </AutoLayout>
  );
}

export default Deal;
